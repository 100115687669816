import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { QueueTimes } from "src/app/core/enums/queue-times.enum";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { API_URL } from "src/environments/settings";
import { LaborFilters } from "../interfaces/labor-filters.interface";

@Injectable({ providedIn: "root" })
export class RosterService {

	private apiUrl: string;

	constructor(
		private http: HttpClient,
		private env: EnvironmentService
	) {
		this.apiUrl = this.env.getValueTransferState(API_URL);
	}

	getWorkers(skipCount: number, maxResult: number, filters?: LaborFilters) {
		if (this.env.isBrowser) {
			let params = new HttpParams({
				fromObject: {
					SkipCount: skipCount,
					MaxResultCount: maxResult,
					isFromRosterList: true
				}
			});

			if (filters?.UnionId) {
				params = params.append('UnionId', filters.UnionId);
			}
			if (filters?.searchQ) {
				params = params.append('searchQ', filters.searchQ);
			}
			if (Number.isInteger(filters?.status)) {
				params = params.append('UserStatus', filters.status);
			}

			return this.http.get<any>(`${this.apiUrl}/app/worker/rosterList`, { params });
		}
	}

	getWorker(
		query: string,
		unionId: number,
		skipCount: number,
		maxResult: number,
		date: Date | string = null,
		availabilityTypes: string[] = null,
		jobTypes: number[],
		addAssigned: boolean = false,
		isActive: boolean,
		isFromAvailability: boolean,
		queueType?: QueueTimes,
		onlyBumpeds?: boolean
	) {
		let availabilityTypesString = "";
		if (availabilityTypes) {
			availabilityTypes.forEach(
				(id) => (availabilityTypesString = availabilityTypesString.concat(`&AvailabilityTypeIds=${id}`))
			);
		}

		let dateString: string = "";
		if (date) {
			const requestedDate: moment.Moment = 
				moment(date)
					.set('hours', moment().get('hours'))
					.set('minutes', moment().get('minutes'));
	
			dateString = `&Date=${requestedDate.format('YYYY-MM-DDTHH:mm:ss')}`;
		}
		const addAssignedString: string = addAssigned ? `&isFromDispatchByDate=true` : "";
		const isActiveString: string = isActive ? `&isActive=true` : "";
		const isAvailability: string = isFromAvailability ? `&IsFromAvailability=true`: '';
		const queueTypeString: string = Number.isInteger(queueType) ? `&queueType=${queueType}` : '';
		const onlyBumpedString: string = onlyBumpeds ? `&getBumped=${onlyBumpeds}` : '';

		let jobTypesString = "";
		if (jobTypes) {
			jobTypes.forEach(
				(id) => (jobTypesString = jobTypesString.concat(`&jobTypes=${id}`))
			);
		}

		if (unionId) {
			return this.http.get<any>(
				`${this.apiUrl}/app/worker?UnionId=${unionId}&SearchQ=${query}&MaxResultCount=${maxResult}&SkipCount=${skipCount}${dateString}${availabilityTypesString}${addAssignedString}${isActiveString}${queueTypeString}${isAvailability}${jobTypesString}${onlyBumpedString}`
			);
		} else {
			return this.http.get<any>(
				`${this.apiUrl}/app/worker?SearchQ=${query}&MaxResultCount=${maxResult}&SkipCount=${skipCount}${dateString}${availabilityTypesString}${addAssignedString}${isActiveString}${queueTypeString}${jobTypesString}${onlyBumpedString}`
			);
		}
	}

	remove(workerId: number) {
		return this.http.delete(`${this.apiUrl}/app/worker/${workerId}`);
	}

	changeStatus(workerId: number) {
		return this.http.get(`${this.apiUrl}/app/worker/changeStatus/${workerId}`);
	}

	sendTextToWorker(workerId: number, text: string, unionId: number) {
		return this.http.post(`${this.apiUrl}/app/worker/${workerId}/sendSmsToWorker`, {
			text, 
			unionId
		});
	}

	saveNote(workerId: number, value: any[]) {
		return this.http.post<any>(`${this.apiUrl}/app/worker/${workerId}/enhancedNotes`, value);
	}

	getWorkerStatus() {
		return this.http.post<any>(`${this.apiUrl}/app/prefillProvider/getPrefillData`, {
			includeRequests: [
				{
          entityName: "UserStatus",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "status"
        }
			]
		}).pipe(
			map(
				(response: any) => {
					return response.prefillData[0].filterData.map((item) => {
						return { text: item.value, value: item.key };
					});
				}
			)
		);
	}

	checkCertificationsImport(form: FormData) {
    return this.http.post<any>(`${this.apiUrl}/app/workerCertification/checkFile`, form);
  }

  importCertifications(form: FormData) {
    return this.http.post<any>(`${this.apiUrl}/app/workerCertification/uploadFile`, form);
  }

	checkDebitsImport(form: FormData) {
    return this.http.post<any>(`${this.apiUrl}/app/workerDebit/checkFile`, form);
  }

  importDebits(form: FormData) {
    return this.http.post<any>(`${this.apiUrl}/app/workerDebit/uploadFile`, form);
  }

	findTenantWorkers(query: string) {
    let params = new HttpParams({
      fromObject: {
        SkipCount: 0,
        MaxResultCount: 15,
        SearchQ: query
      }
    });

    return this.http.get<any>(`${this.apiUrl}/app/worker/globalRosterList`, { params });
  }

  hasWorkerUnion(workerId: string, unionId: string): Observable<boolean> {
    let params = new HttpParams({
      fromObject: {
        workerId,
        unionId
      }
    });
    
    return this.http.get<any>(`${this.apiUrl}/app/worker/hasWorkerUnion`, { params });
  }
}
